<template>
  <div>
    <div @click="$router.go(-1)"
      style="display: flex;align-items: center;margin-left: 20px;font-size: 20px;cursor: pointer;margin-top: 20px;">
      <a-icon style="font-size: 30px;margin-right: 10px;" type="left-circle" />
      <span>返回</span>
    </div>
    <a-card style="background-color: #fff; " class="main-box">
      <div :class="advanced ? 'search' : null" v-if="hasPermission('GOODSAUTARKY_LIST')">
        <a-form layout="horizontal">
          <div :class="advanced ? null : 'fold'">
            <a-row>
              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="商品名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-input v-model="searchParams.name" placeholder="请输入商品名称" />
                </a-form-item>
              </a-col>

              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="商品ID" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-input placeholder="请输入商品ID" v-model="searchParams.sku" />
                </a-form-item>
              </a-col>

              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="供应商ID" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-input placeholder="请输入商品ID" v-model="searchParams.supplierId" type="number" />
                </a-form-item>
              </a-col>
              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="质检报告" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-select v-model="searchParams.isQirPic" style="width: 100%" placeholder="可多选"
                    option-label-prop="label">
                    <a-select-option value="1" label="是">
                      是
                    </a-select-option>
                    <a-select-option value="0" label="否">
                      否
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>


            </a-row>


            <a-row style="margin: 4px 0;">
              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="平台售价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-row style="display: flex;">
                    <a-input v-model="searchParams.minPlfPrice" placeholder="最小" />
                    <a-col :span="2" style="text-align:center">
                      -
                    </a-col>
                    <a-input v-model="searchParams.maxPlfPrice" placeholder="最大" />
                  </a-row>
                </a-form-item>
              </a-col>

              <a-col :md="iptmd" :sm="iptsm">
                <a-form-item label="一件代发价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-row>
                    <a-col :span="11">
                      <a-input v-model="searchParams.minPrice" placeholder="最小" />
                    </a-col>
                    <a-col :span="2" style="text-align:center">
                      -
                    </a-col>
                    <a-col :span="11">
                      <a-input v-model="searchParams.maxPrice" placeholder="最大" />
                    </a-col>
                  </a-row>
                </a-form-item>
              </a-col>

              <a-col :md="iptmd" :sm="iptsm">

                <a-form-item label="电商售价" :labelCol="{ span: 5 }" :wrapperCol="{ span: 17, offset: 1 }">
                  <a-row style="display: flex;">
                    <a-input v-model="searchParams.minMarketPrice" placeholder="最小" />
                    <a-col :span="2" style="text-align:center">
                      -
                    </a-col>
                    <a-input v-model="searchParams.maxMarketPrice" placeholder="最大" />
                  </a-row>
                </a-form-item>
              </a-col>




              <a-col :md="iptmd" :sm="iptsm">
                <span class="btn-box" style="margin-left: 10px;display: inline-block;">
                  <a-button style="margin-left: 8px" @click="resetChange">重置</a-button>
                  <a-button type="primary" @click="searchChange">查询</a-button>
                </span>
              </a-col>
            </a-row>



          </div>

        </a-form>


      </div>

      <div class="select-list" v-if="hasPermission('GOODSAUTARKY_LIST')">
        <ul style="margin-bottom: 8px;">
          <li v-for="(item, index) in goodsType" :class="typeCurrent == index ? 'active-sel' : ''" :key="item.id"
            @click="filterTypeChange(index)">
            <img v-if="typeCurrent == index" :src="item.selIcon" alt="">
            <img v-else :src="item.icon" alt="">
            {{ item.name }}
          </li>
        </ul>
        <ul>
          <li v-for="(item, index) in orderType" :class="ordCurrent == index ? 'active-sel' : ''" :key="item.id"
            @click="filterOrderChange(index)">
            <img v-if="ordCurrent == index" :src="item.selIcon" alt="">
            <img v-else :src="item.icon" alt="">
            {{ item.name }}
          </li>
        </ul>
      </div>

      <div style="height: 77vh;display:flex;border: 1px solid #F3F3F3;border-bottom: 0;width: 100%;">
        <div class="left-top" style="position: relative;" v-if="hasPermission('PROGRAMME_GROUP_INFO')">

          <div style="padding: 0 15px;">
            <div class="items-title">方案列表</div>
            <ul class="items-list">
              <a-popover>
                <template slot="content">
                  产品售价 = 产品售价 -（业务支出 / 采购数量）
                </template>
                <li style="display: flex;">
                  <p>产品售价: <span>¥{{ progDetail.productPrice }}</span></p>
                  <p style="margin: 0 10px 0 30px;">产品预算: <span>¥{{ progDetail.productBudget }}</span></p>

                  <!-- <p>采购数量: <span>¥{{ progDetail.purchaseQuantity }}</span></p> -->
                  <p><a-icon type="info-circle" /></p>
                </li>
              </a-popover>

              <li style="display: flex;">
                <p>方案类型: <span>{{ progDetail.type == 3 ? '提货券' : progDetail.type == 4 ? '货堆' : progDetail.type == 5 ? '宅配'
                  :
                  progDetail.type == 6 ? '其他' : '' }}</span></p>
                <p style="margin-left: 20px;">M+: <span>¥{{ progDetail.businessExpenses }}</span></p>
              </li>
              <li>
                <p>产品方案：<span>{{ progDetail.groupNumber ? progDetail.groupNumber : 0 }}组</span></p>
                <p style="margin-left: 30px;">合计供应商：<span>{{ progDetail.supplierNumber ? progDetail.supplierNumber :
                  0 }}个</span></p>
              </li>
              <li>每组平均成本：<span>¥{{ progDetail.groupCost ? progDetail.groupCost : 0 }}</span> </li>
              <li v-for="(item, index) in classfiyData" :key="index" style="display: flex;flex-direction: column;">
                <div>食品毛利率需 ≥<span v-if="item.classifyType == 1 && item"> {{ item.programmeProfitMargin }}%</span><span
                    v-else> 0%</span></div>
                <div style="margin: 5px 0;">非食品毛利率需 ≥<span v-if="item.classifyType == 2 && item"> {{
                  item.programmeProfitMargin }}%</span><span v-else> 0%</span></div>
                <div>食品&非食混搭组合毛利率需 ≥<span v-if="item.classifyType == 3 && item"> {{ item.programmeProfitMargin
                }}%</span><span v-else> 0%</span></div>
              </li>
            </ul>
          </div>

          <!--  组列表 -->
          <div class="prog-mai" v-if="progList.length > 0">
            <div class="select-nav">
              <p @click="groupSrot(0)">创建
                <img v-if="groupParams.createTimeOrderBy == 'asc'" src="https://qiniu.youjiamall.cn/seltop.png" alt="">
                <img v-else src="https://qiniu.youjiamall.cn/selbtm.png" alt="">
              </p>
              <p @click="groupSrot(1)">更新
                <img v-if="groupParams.updateTimeOrderBy == 'asc'" src="https://qiniu.youjiamall.cn/seltop.png" alt="">
                <img v-else src="https://qiniu.youjiamall.cn/selbtm.png" alt="">
              </p>
              <p @click="groupSrot(2)">
                电商价
                <img v-if="groupParams.marketPriceOrderBy == 'asc'" src="https://qiniu.youjiamall.cn/seltop.png" alt="">
                <img v-else src="https://qiniu.youjiamall.cn/selbtm.png" alt="">
              </p>
              <p @click="groupSrot(3)">
                利润率
                <img v-if="groupParams.profitMarginOrderBy == 'asc'" src="https://qiniu.youjiamall.cn/seltop.png" alt="">
                <img v-else src="https://qiniu.youjiamall.cn/selbtm.png" alt="">
              </p>
            </div>
            <a-checkbox-group @change="selectChange">
              <div class="prog-mai-item" v-for="(item, index) in progList" :key="item.id">
                <div class="prog-tit">
                  <div style="display: flex;align-items: center;">
                    <a-checkbox :value="item.id" style="margin-right: 10px;" />
                    <!-- <p style="font-size: 14px;">{{ item.name }} </p> -->
                    <p style="font-size: 14px;">{{ index + 1 }} </p>
                    <div style="display: flex;cursor: pointer;">
                      <p @click="moveProg(index, 0)">
                        <img src="https://qiniu.youjiamall.cn/ztop.png" style="width: 10px;height: 12px;margin: 0 5px;"
                          alt="">
                      </p>
                      <p @click="moveProg(index, 1)">
                        <img src="https://qiniu.youjiamall.cn/zbtm.png" style="width: 10px;height: 12px;" alt="">
                      </p>
                      <p @click="moveProg(index, 2)">
                        <img src="https://qiniu.youjiamall.cn/xtop.png" style="width: 10px;height: 12px; margin: 0 5px;"
                          alt="">
                      </p>
                      <p @click="moveProg(index, 3)">
                        <img src="https://qiniu.youjiamall.cn/xbtm.png" style="width: 10px;height: 12px;" alt="">
                      </p>
                    </div>

                  </div>
                  <div>
                    <p>成本：¥{{ item.price }}</p>
                    <p>
                      <a-icon type="arrow-up" v-if="item.profit > 0" style="color: red;" />
                      <a-icon v-if="item.profit < 0" type="arrow-down" style="color:green" />
                      <span v-if="item.profit > 0" style="color: red !important;">
                        {{ item.profit }}｜{{ item.profitMargin }}%
                      </span>
                      <span v-if="item.profit < 0" style="color: green !important;">
                        {{ item.profit }}｜{{ item.profitMargin }}%
                      </span>
                    </p>
                  </div>
                </div>
                <div class="prog-list">
                  <ul>
                    <li v-for="goods in item.sfaGoodsAutrakyList" :key="goods.id">
                      <div class="prog-ls-top">
                        <img :src="goods.imagePath" alt="">
                        <img v-if="hasPermission('DELETE_GROUP_GOODS')" class="close-icon"
                          src="https://qiniu.youjiamall.cn/xdel.png" @click="deleteGoods(goods, item.id)"
                          style="width: 20px;height: 20px;" alt="">
                        <div style="width: 80%;">
                          <p class="ls-top-tit text-wrap2">{{ goods.autarkySpecsList[0].packName }}</p>
                          <p class="ls-top-tit text-wrap2">{{ goods.name }}</p>
                          <a :href="goods.thirdUrl" target="_blank" v-if="goods.isThirdUrl == 1 && goods.thirdUrl">H</a>
                          <a href="javascript:;" v-if="goods.isThirdUrl == 1 && !goods.thirdUrl">H</a>
                          <a-popover>
                            <template slot="content">
                              7天无理由退换货
                            </template>
                            <a href="javascript:;" v-if="goods.returnable == 1">7</a>
                          </a-popover>
                          <a href="javascript:;" v-if="goods.isQirPic == 1">质</a>
                        </div>
                      </div>
                      <div class="prog-ls-btm">
                        <div style="display: flex;">
                          <p>
                            <span>一件代发价：</span>¥{{ goods.autarkySpecsList[0].price }}
                          </p>
                          <p style="margin-left: 20px;">
                            <span>京东电商价：</span>¥{{ goods.autarkySpecsList[0].marketPrice }}
                          </p>
                        </div>
                        <p>
                          <span>供应商 ID：</span>{{ goods.supplierId }}
                        </p>
                        <p>
                          <span>创建时间：</span>{{ formatDate(goods.createTime) }}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </a-checkbox-group>


          </div>

          <div v-else style="padding-top: 200px;">
            <a-empty :image="simpleImage" />
          </div>

          <div class="prog-btm" ref="progBtm">
            <!-- <a-checkbox @change="onCheckAllChange" style="color: #fff;">全选</a-checkbox> -->
            <a-button style="margin-left: 8px;border: 1px solid #fff;color: #fff;background: #5542F6;" @click="delConfirm"
              v-if="hasPermission('DELETE_GROUP')">删除</a-button>
            <a-button style="background-color: #fff;color: #5542F6" @click="checkProgrammeSelection"
              v-if="hasPermission('GENERATE_PROGRAMME')">生成方案</a-button>
          </div>

          <div style="height: 62px;"></div>
        </div>

        <!-- 产品池 -->
        <div class="items-right">
          <div class="items-title">产品列表</div>
          <div v-if="hasPermission('GOODSAUTARKY_LIST')">
            <ul v-if="prodList.length > 0">
              <li v-for="(item, index) in prodList" :key="index">
                <div class="items-tag" v-if="item.type == 2">礼包</div>
                <p style="text-align: center;" class="rig-img-box">
                  <img :src="item.imagePath" style="object-fit: cover;width: 100%;height: 100%;" alt="">
                </p>
                <div class="prog-ls-top items-ls-top">

                  <p class="ls-top-tit text-wrap1" style="height: 22px;">{{ item.packName }}</p>
                  <p class="ls-top-tit text-wrap1">{{ item.name }}</p>
                  <p>
                    <a :href="item.thirdUrl" target="_blank" v-if="item.isThirdUrl == 1 && item.thirdUrl">H</a>
                    <a href="javascript:;" v-if="item.isThirdUrl == 1 && !item.thirdUrl">H</a>
                    <a-popover>
                      <template slot="content">
                        7天无理由退换货
                      </template>
                      <a href="javascript:;" v-if="item.returnable == 1">7</a>
                    </a-popover>
                    <a href="javascript:;" v-if="item.isQirPic == 1">质</a>
                  </p>
                </div>
                <div class="prog-ls-btm" style="padding-top: 0;">
                  <p>
                    <span>一件代发售价：</span>¥{{ item.price }}
                  </p>
                  <p>
                    <span>集采价：</span>¥{{ item.cargoPilePrice }}
                  </p>
                  <p>
                    <span>优加售价：</span>¥{{ item.plfPrice }}
                  </p>
                  <p>
                    <span>京东电商价：</span>¥{{ item.marketPrice }}
                  </p>
                  <p>
                    <span>供应商 ID：</span>{{ item.supplierId }}
                  </p>
                  <p>
                    <span>创建时间：</span>{{ formatDate(item.createTime) }}
                  </p>
                </div>
                <div class="items-btn">
                  <a-button style="margin-left: 8px" @click="createProg(item, 0)"
                    v-if="hasPermission('SAVE_TO_GROUP')">加入方案</a-button>
                  <a-button type="primary" @click="createProg(item, 1)"
                    v-if="hasPermission('SAVE_GROUP')">创建新方案</a-button>
                </div>
              </li>


            </ul>

            <div v-else class="items-empty">
              <a-empty :image="simpleImage" />
            </div>

            <div class="page-box">
              <a-pagination :total="total" :current="currentPage" :pageSize="queryData.size" @change="handlePageChange" />
            </div>
          </div>

          <div v-if="!hasPermission('GOODSAUTARKY_LIST')" style="text-align: center;font-size: 20px;margin-top: 100px;">
            <a-empty :description="false" />
            无权限查看
          </div>

        </div>


        <!-- 选择加入哪个方案 -->
        <a-modal v-model="selectVisible" title="请选择要加入到哪个方案" @ok="handleSelectOk">
          <a-radio-group v-model="progId" @change="popSelectChange">
            <div v-for="(item, index) in progList" :key="index" style="margin-bottom: 10px;">
              <a-radio :value="item.id">
                {{ index + 1 }}
              </a-radio>
            </div>
          </a-radio-group>
        </a-modal>
      </div>

    </a-card>
  </div>
</template>

<script>
// import StandardTable from '@/components/table/StandardTable'
import { request } from '@/utils/request'
import { Empty } from 'ant-design-vue';

export default {
  name: 'QueryList',
  // components: { StandardTable },
  data() {
    return {
      iptmd: 4,
      iptsm: 22,
      form: this.$form.createForm(this),
      searchParams: {
        "page": 0,
        "size": 12,
      },
      queryData: {
        "page": 0,
        "size": 12,
      },
      currentPage: 1,

      goodsType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '单品', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon4.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon04.png' }, { name: '礼包', id: 2, icon: 'https://qiniu.youjiamall.cn/tupianxs.png', selIcon: 'https://qiniu.youjiamall.cn/tupianxs1.png' }],
      orderType: [{ name: '全部', id: 0, icon: 'https://qiniu.youjiamall.cn/xcicon3.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon03.png' }, { name: '热销', id: 1, icon: 'https://qiniu.youjiamall.cn/xsicon2.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon02.png' }, { name: '新品', id: 2, icon: 'https://qiniu.youjiamall.cn/xsicon1.png', selIcon: 'https://qiniu.youjiamall.cn/xsicon01.png' }],
      ordCurrent: 0,
      typeCurrent: 0,


      advanced: true,
      dataSource: [],
      selectedRows: [],
      pagination: {
        current: 1,
        pageSize: 12,
        total: 0
      },
      total: 0,
      current: 1,
      value: ['china'],

      // 新建方案
      ModalText: 'Content of the modal',
      visible: false,
      confirmLoading: false,

      // 新建方案弹窗
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },

      prodList: [],

      progList: [],

      selectVisible: false,//加入方案弹窗
      progId: "",//组id
      delVisible: false,//确认删除弹窗

      // 排序参数
      groupParams: {
        "page": 0,
        "size": 100,
        "createTimeOrderBy": "asc",
        "updateTimeOrderBy": "",
        "marketPriceOrderBy": "",
        "profitMarginOrderBy": ""
      },

      classfiyData: {},
      progDetail: {},

    }
  },
  authorize: {
    deleteRecord: 'delete'
  },
  // watch: {
  //   $route(to, from) {
  //     if (to.path !== from.path) {
  //       console.log(to, from)
  //       this.setProgBtmWidth();
  //       window.addEventListener('resize', this.setProgBtmWidth); // 监听窗口大小变化，实时更新宽度
  //       this.getData();//查询列表
  //       this.getGroupList();//查询组
  //       this.getProgDetail();//查详情
  //     }
  //   }
  // },
  mounted() {

    this.getData();//查询列表
    this.getGroupList();//查询组
    this.getProgDetail();//查详情
    this.setProgBtmWidth();
    window.addEventListener('resize', this.setProgBtmWidth); // 监听窗口大小变化，实时更新宽度
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'validate_other' });
    this.simpleImage = Empty.PRESENTED_IMAGE_SIMPLE;
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    selectChange(checkedList) {
      this.delGroupIdList = checkedList;
    },
    onCheckAllChange() {
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    // 查询方案详情
    getProgDetail() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/findById/' + this.$route.query.id, 'get').then(res => {
        if (res.data.code == 200) {
          this.progDetail = res.data.data;
        }
      })
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/SfaClassifyExpandController/list', 'post').then(res => {
        if (res.data.code == 200) {
          this.classfiyData = res.data.data;
        }
      })

    },

    // 升序降序
    groupSrot(type) {
      switch (type) {
        case 0:

          this.groupParams = {
            "page": 0,
            "size": 12,
            "createTimeOrderBy": this.groupParams.createTimeOrderBy == 'desc' ? this.groupParams.createTimeOrderBy = 'asc' : this.groupParams.createTimeOrderBy = 'desc',
            "updateTimeOrderBy": "",
            "marketPriceOrderBy": "",
            "profitMarginOrderBy": ""
          }
          break;
        case 1:

          this.groupParams = {
            "page": 0,
            "size": 12,
            "createTimeOrderBy": "",
            "updateTimeOrderBy": this.groupParams.updateTimeOrderBy == 'desc' ? this.groupParams.updateTimeOrderBy = 'asc' : this.groupParams.updateTimeOrderBy = 'desc',
            "marketPriceOrderBy": "",
            "profitMarginOrderBy": ""
          }
          break;
        case 2:

          this.groupParams = {
            "page": 0,
            "size": 12,
            "createTimeOrderBy": "",
            "updateTimeOrderBy": "",
            "marketPriceOrderBy": this.groupParams.marketPriceOrderBy == 'desc' ? this.groupParams.marketPriceOrderBy = 'asc' : this.groupParams.marketPriceOrderBy = 'desc',
            "profitMarginOrderBy": ""
          }
          break;
        case 3:
          this.groupParams = {
            "page": 0,
            "size": 12,
            "createTimeOrderBy": "",
            "updateTimeOrderBy": "",
            "marketPriceOrderBy": "",
            "profitMarginOrderBy": this.groupParams.profitMarginOrderBy == 'desc' ? this.groupParams.profitMarginOrderBy = 'asc' : this.groupParams.profitMarginOrderBy = 'desc'
          }


          break;
      }
      this.getGroupList();//查询组
    },
    // 置顶置地
    moveProg(idx, type) {
      let item;
      switch (type) {
        case 0:
          item = this.progList.splice(idx, 1)[0];
          this.progList.unshift(item);
          break;
        case 1:
          item = this.progList.splice(idx, 1)[0];
          this.progList.push(item);
          break;
        case 2:
          item = this.progList.splice(idx, 1)[0];
          this.progList.splice(idx - 1, 0, item);
          break;
        case 3:
          item = this.progList.splice(idx, 1)[0];
          this.progList.splice(idx + 1, 0, item);
          break;
      }

    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    // 筛选
    filterTypeChange(idx) {
      this.$message.loading();
      if (idx == 0) {
        this.queryData.type = ''
      } else {
        this.queryData.type = this.goodsType[idx].id
      }
      this.getData();
      this.typeCurrent = idx;
    },
    filterOrderChange(idx) {
      this.$message.loading();
      if (idx == 0) {
        this.queryData.order = ''
      } else {
        this.queryData.order = this.orderType[idx].id
      }
      this.getData();
      this.ordCurrent = idx;

    },
    // 生成前校验方案
    checkProgrammeSelection() {
      let element = this.progList.find(item => item.flag === false);
      if (element) {
        this.$message.error('利润率校验未通过！')
        return;
      }
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/group/checkProgrammeSelection/' + this.$route.query.id, 'get').then(res => {
        this.$message.destroy();
        if (res.data.code == 200) {
          if (res.data.data) {
            this.groupSave();
          } else {
            this.$message.error('校验未通过！')
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 生成方案
    groupSave() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/group/save', 'post', [{ programmeId: this.$route.query.id }]).then(res => {
        if (res.data.code == 200) {
          this.$message.success('操作成功！')
          this.$router.replace('/sfa_sales_programs/product_solution')
        }
      })
    },
    // 删除单个商品
    deleteGoods(goods, id) {
      let _that = this;
      this.$confirm({
        title: '您确定要删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          _that.$message.loading()
          let params = {
            "groupId": id,
            "sku": goods.autarkySpecsList[0].sku,
            "autarkyId": goods.id,
            groupGoodsId: goods.autarkySpecsList[0].groupGoodsId
          }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/group/delGroupGoods', 'post', params).then(res => {
            if (res.data.code == 200) {
              _that.$message.destroy();
              _that.$message.success('删除成功！')
              _that.getGroupList()
              _that.getProgDetail()
            } else {
              _that.$message.error(res.data.message)
            }
          })
        }
      });


    },
    // 是否删除
    delConfirm() {
      if (!this.delGroupIdList) {
        this.$message.warning('请选择要删除的方案！')
        return;
      }
      let that = this;
      this.$confirm({
        title: '您确定要删除吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          that.delGroup()
        }
      });
    },
    // 删除组
    delGroup() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/group/delGroup', 'post', this.delGroupIdList).then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功！')
          this.getGroupList();
          this.getProgDetail();
        }
      })
    },
    // 计算窗口宽度
    setProgBtmWidth() {
      const progBtm = this.$refs.progBtm;
      const parentWidth = progBtm.parentNode.offsetWidth;
      progBtm.style.width = parentWidth + 'px';
    },
    handleSelectOk() {
      if (!this.progId) {
        this.$message.warn('请选择')
        return;
      } else {
        let url = 'sfa/group/saveToGroup'
        let params = {
          "programmeId": this.$route.query.id,
          "packName": this.selectItem.packName,
          id: this.progId,
          "autarkyIdlist": [this.selectItem.id]
        }
        request(process.env.VUE_APP_API_BASE_URL + url, 'post', params).then(res => {
          if (res.data.code == 200) {
            this.selectVisible = false;
            this.getGroupList()
          } else {
            this.$message.error(res.data.message)
          }

        })
      }
    },
    popSelectChange(e) {
      this.progId = e.target.value;
    },

    // 查询组列表
    getGroupList() {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/programme/findGroupById/' + this.$route.query.id, 'post', this.groupParams).then(res => {
        if (res.data.code == 200) {
          let data = res.data.data;
          this.progList = data.data;
          this.checkSelection(); //校验利润率 
          this.getProgDetail();//查详情 
        }
      })
    },
    // 校验利润率
    checkSelection() {
      if (this.progList.length == 0) return;
      this.progList.forEach(item => {
        request(process.env.VUE_APP_API_BASE_URL + 'sfa/group/checkSelection', 'post', [{
          "id": item.id,
          "programmeId": this.$route.query.id
        }]).then(res => {
          item.flag = res.data.data[0].flag;
        })
      })
    },
    // 创建/加入到组
    createProg(item, type) {
      if (type == 0) {
        if (this.progList.length > 0) {
          this.selectItem = item;
          this.selectVisible = true;
        } else {
          this.$message.warn('请先创建新方案')
        }
        return;
      }
      this.$message.loading();
      let url = 'sfa/group/saveGroup'
      let params = {
        "programmeId": this.$route.query.id,
        "packName": item.packName,
        "autarkyIdlist": [item.id]
      }
      request(process.env.VUE_APP_API_BASE_URL + url, 'post', params).then(res => {
        this.$message.destroy()
        if (res.data.code == 200) {

          this.$message.success('创建成功')
          this.getGroupList()
        } else {
          this.$message.error(res.data.message)
        }

      })
    },

    // 查询
    searchChange() {
      // if (this.searchParams.supplierId) {
      //   this.searchParams.supplierId = this.searchParams.supplierId - 10000
      // }
      this.$message.loading();
      this.searchParams.programmeId = this.$route.query.id;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/goodsautarky/list', 'post', this.searchParams).then(res => {
        this.$message.destroy()
        // if (this.searchParams.supplierId) {
        //   this.searchParams.supplierId = this.searchParams.supplierId - 0 + 10000
        // }
        let data = res.data.data;
        if (res.data.code == 200) {
          this.prodList = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.current = data.page + 1;
          this.total = data.total;
        }
      })
    },
    resetChange() {
      this.searchParams = {
        "page": 0,
        "size": 12,
      }
      this.ordCurrent = 0;
      this.typeCurrent = 0;
      this.searchChange()
    },
    handleOk(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    // handleOk() {
    //   this.ModalText = 'The modal will be closed after two seconds';
    //   this.confirmLoading = true;
    //   setTimeout(() => {
    //     this.visible = false;
    //     this.confirmLoading = false;
    //   }, 2000);
    // },
    normFile(e) {
      console.log('Upload event:', e);
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    },

    getData() {
      this.queryData.programmeId = this.$route.query.id;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/goodsautarky/list', 'post', this.queryData).then(res => {
        let data = res.data.data;
        this.$message.destroy()
        if (res.data.code == 200) {
          this.prodList = data.data;
          this.queryData.page = data.page;
          this.queryData.size = data.size;
          this.total = data.total;
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.search input {
  padding-left: 10px;
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}


::v-deep .page-content {
  padding: 0 !important;
}

.page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.left-top {
  position: relative;
  padding: 0 0px 20px;
  box-sizing: border-box;
  width: 20%;
  border-right: 1px solid #f3f3f3;
  overflow-y: scroll;
}


.items-right {
  width: 80%;
  padding: 0 27px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.items-title {
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #010101;
  margin: 10px 0;
}

.items-list {
  list-style: none;
  padding: 10px;
  box-sizing: border-box;
  // height: 232px;
  background: #E8ECF9;
  border-radius: 8px;
}

.items-list li {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  margin-bottom: 4px;
}


.items-list span {
  color: #5542F6;
}

.select-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 15px;
  box-sizing: border-box;
}

.select-nav>p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-nav img {
  width: 10px;
  height: 14px;
  margin-left: 6px;
  vertical-align: middle;
}

.select-nav p {
  cursor: pointer;
}

.select-nav p {
  width: 20%;
  text-align: center;
  background: #F7F9FA;
  border-radius: 20px;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #93989A;
  padding: 6px 0px;
}

.prog-tit p {
  margin-right: 12px;
}

.prog-tit {
  margin-top: 10px;
  width: 100%;
  height: 46px;
  background: rgba(98, 113, 235, .15);
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #010101;
  justify-content: space-between;
  margin-bottom: 8px;
}

.prog-tit div {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #5542F6;
}

.prog-list {
  padding: 0 15px;
  box-sizing: border-box;
  min-height: 150px;
}

.prog-list li {
  border-radius: 8px;
  border: 1px solid #EBEAED;
  margin-bottom: 8px;
}

.prog-ls-top {
  position: relative;
  padding: 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #EBEAED;
}

.prog-ls-top a {
  display: inline-block;
  width: 24px;
  background: #E8ECF9;
  height: 24px;
  margin-right: 6px;
  text-align: center;
}

.prog-ls-top img {
  margin-right: 12px;
  width: 55px;
  height: 55px;
  border-radius: 8px;
  border: 1px solid #EBEAED;
}

.ls-top-tit {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0D0A19;
  text-align: left;
  width: 90%;
}

.prog-ls-btm {
  color: #0D0A19;
  padding: 10px 12px;
  box-sizing: border-box;
}

.prog-ls-btm p {
  margin-bottom: 4px;
}

.prog-ls-btm span {
  color: #84818A;
}


.prog-btm {
  position: fixed;
  z-index: 9;
  bottom: 0;
  width: 18%;
  height: 50px;
  background: #5542F6;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

// .prog-btm {
//   position: fixed;
//   z-index: 9;
//   bottom: 0;
//   height: 50px;
//   background: #5542F6;
//   font-size: 14px;
//   font-family: PingFangSC, PingFang SC;
//   font-weight: 600;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   width: 100%;
// }

.prog-btm button {
  width: 35%;
  height: 36px;
  border-radius: 8px;
}


.tems-right {
  padding: 10px 12px;
  box-sizing: border-box;
}

.items-right ul {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
}

.items-right li {
  position: relative;
  width: 15%;
  margin-right: 1.5%;
  /* 计算每个项的宽度 */
  // margin-right: 26px;
  border-radius: 14px;
  border: 1px solid #E4E6E8;
  padding: 10px 0;
  margin-bottom: 10px;
}

::v-deep .ant-card-body {
  padding: 12px !important;
  min-width: 1600px;
  overflow-x: auto;
}

// .ant-card-body {
//     padding: 12px !important;
//     zoom: .1 !important;
//   }
// @media (max-width: 1800px) {
//   ::v-deep .ant-card-body {
//     padding: 12px !important;
//     zoom: .8 !important;
//   }

//   ::v-deep .ant-col-md-4 {
//     width: 20% !important;
//     margin-right: 20px;
//   }

//   // /* 当前页面宽度小于1500px时应用的样式 */
//   .items-right {
//     width: 80%;
//   }

//   .items-right li {
//     width: 29%;
//     margin-right: 26px;
//     border-radius: 14px;
//     border: 1px solid #E4E6E8;
//     padding: 10px 0;
//     margin-bottom: 12px;
//   }


//   .left-top {
//     position: relative;
//     padding: 0 0px 20px;
//     box-sizing: border-box;
//     width: 35%;
//     border-right: 1px solid #f3f3f3;
//     overflow-y: scroll;
//   }
// }

.items-right .rig-img-box {
  margin: auto;
  width: 128px;
  height: 112px;
}

::v-deep .ant-card-bordered {
  border: none !important;
}

.ant-card-bordered {
  border: none !important;
}

.items-ls-top {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  border: none !important;
}

.items-btn {
  display: flex;
}

.items-btn button {
  width: 45%;
  height: 36px;
  margin-right: 10px;
  font-size: 13px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  border-radius: 8px;
}

.items-btn button:nth-child(1) {
  border: 1px solid #5542F6;
  color: #5542F6;
}

.items-btn button:nth-child(2) {
  margin-right: 0;
}

.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 6px;
}

.items-empty {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}


.ant-checkbox-group {
  width: 100%;
}



.select-list {
  padding: 4px 0;
  border-top: 1px solid #F3F3F3;
}

.select-list img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.select-list ul {
  display: flex;
}

.select-list li {
  cursor: pointer;
  width: 83px;
  height: 36px;
  border-radius: 8px;
  background: #F7F9FA;
  font-size: 14px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #93989A;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.select-list .active-sel {
  background: #5542F6;
  color: #fff;
}



.text-wrap1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-wrap2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* 控制显示的行数 */
  overflow: hidden;
}

.close-icon {
  position: absolute;
  right: 10px;
  top: 18px;
  cursor: pointer;
}



@media (min-width: 1501px) and (max-width: 2099px) {

  /* 在这里写你想要应用的样式 */
  .left-top {
    position: relative;
    padding: 0 0px 20px;
    box-sizing: border-box;
    width: 25%;
    border-right: 1px solid #f3f3f3;
    overflow-y: scroll;
  }

  .prog-btm {
    width: 20%;
  }

  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    width: 20% !important;
    margin-right: 20px;
  }
}

@media (max-width: 1500px) {

  /* 在这里写你想要应用的样式 */
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  // /* 当前页面宽度小于1500px时应用的样式 */
  .items-right {
    width: 75%;
  }

  .items-right li {
    width: 23%;
    margin-right: 26px;
    border-radius: 14px;
    border: 1px solid #E4E6E8;
    padding: 10px 0;
    margin-bottom: 12px;
  }


  .left-top {
    position: relative;
    padding: 0 0px 20px;
    box-sizing: border-box;
    width: 23%;
    border-right: 1px solid #f3f3f3;
    overflow-y: scroll;
  }

  .prog-btm {
    width: 25%;
  }

  .page-box {
    justify-content: left;
  }

}


@media (min-width: 1500px) and (max-width: 1750px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  // /* 当前页面宽度小于1500px时应用的样式 */
  .items-right {
    width: 70%;
  }

  .items-right li {
    width: 22%;
    margin-right: 26px;
    border-radius: 14px;
    border: 1px solid #E4E6E8;
    padding: 10px 0;
    margin-bottom: 12px;
  }


  .left-top {
    position: relative;
    padding: 0 0px 20px;
    box-sizing: border-box;
    width: 23%;
    border-right: 1px solid #f3f3f3;
    overflow-y: scroll;
  }

  .prog-btm {
    width: 20%;
  }

  .page-box {
    // justify-content: left;
  }
}





.items-tag {
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
  height: 20px;
  background: #E8ECF9;
  border-radius: 0px 14px 0px 10px;
  border: 1px solid #5542F6;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #5542F6;
}

.active {
  color: blue;
}

.text-danger {
  color: red;
}




@media (max-width: 1800px) {
  .items-right li {
    width: 18%;
  }
}

@media (max-width: 1730px) {
  .items-right li {
    width: 16%;
  }
}

@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 20% !important;
  }

  .items-right li {
    width: 24%;
  }

  .page-box {
    justify-content: left;
  }
}</style>

